import React, { Component } from 'react';
import { Route, Switch, RouteComponentProps, withRouter  } from 'react-router-dom';
import Layout from './hoc/Layout/Layout';
import Content from './containers/Content/Content';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Video from './components/Video/Video';
import Audio from './components/Audio/Audio';
import Documents from './components/Documents/Documents';

export interface AppProps extends RouteComponentProps<any> {}
export interface AppState {}

class App extends React.Component<AppProps, AppState> {

    render () {
     return (
       <div>
        <div className="background"></div>
         <Layout>
            <Header />
            <Switch>
                <Route path="/video" component={Video} />
                <Route path="/audio" component={Audio} />
                <Route path="/documents" component={Documents} />
                <Route path="/" exact component={Content} />
            </Switch>
            <Footer />
         </Layout>
       </div>
     );
   }
}

export default withRouter<AppProps>(App);
