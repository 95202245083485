import React from 'react';
// import Aux from '../../hoc/Aux/Aux';
import classes from './Audio.module.css';

// <a href="https://soundcloud.com/andrewrapo/sets/the-brementown-musicians-live-1994" target="_blank">Playlist at Soundcloud</a>
// <a href="https://soundcloud.com/andrewrapo/sets/bremen-studio-demos" target="_blank">Playlist at Soundcloud</a>

const audio = (props: any) => (
    <div className={classes.Audio}>
        <div className={classes.AudioTitle}>The Brementown Musicians</div>
        <p>Live (1994)</p>
        <iframe width="60%" height="500" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/683202630&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=false&show_reposts=false&show_teaser=false&visual=false"></iframe>
        <br />
        <p />
        <p>Studio Demos (1994)</p>
        <iframe width="60%" height="500" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/685354596&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=false&show_reposts=false&show_teaser=false&visual=false"></iframe>
        <br />
    </div>
);

export default audio;
