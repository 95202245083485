import React from 'react';

import Logo from '../Logo/Logo'
import classes from './Header.module.css';
import NavigationItems from '../NavigationItems/NavigationItems';

const toolbar = ( props: any ) => (
    <header className={classes.Header}>
        <div className={classes.Logo}>
            <Logo />
        </div>
        <nav className={classes.DesktopOnly}>
            <NavigationItems />
        </nav>
    </header>
);

export default toolbar;
