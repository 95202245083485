import React, { Component } from 'react';

import Aux from '../../hoc/Aux/Aux';
import Product from '../../components/Product/Product';
import classes from './Content.module.css';

export interface ContentProps {}
export interface ContentState {}

class Content extends Component<ContentProps, ContentState> {
    constructor(props:ContentProps) {
        super(props);
        this.state = {};
    }

    render () {
        return (
            <Aux>
                <div className={classes.Content}>
                    <Product />
                </div>
            </Aux>
        );
    }
}

export default Content;
