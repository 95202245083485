import React from 'react';
// import Aux from '../../hoc/Aux/Aux';
import classes from './Documents.module.css';
import leadSheetsImage from '../../assets/images/TheBrementownMusiciansTOC.png';
import mediumArticleImage from '../../assets/images/medium-header-bremen.png';
import facebookImage from '../../assets/images/icon_facebook.svg';

const leadSheets: string[] = [
    "2 Gotta Get Out Of Fieldstown.pdf",
    "3 You Gotta Laugh.pdf",
    "4 Doggone Blues.pdf",
    "5 If You Don't Understand It It's Art.pdf",
    "6 The Life Of A Thief.pdf",
    "7 Lazarus T Wishbone.pdf",
    "8 The Day Will Come.pdf",
    "9 Brementown.pdf",
    "10 Brementown (Reprise).pdf",
    "11 Life In The Theatre.pdf",
    "12 Funny Doesn't Change.pdf",
    "13 Opening Night.pdf",
    "14 Major Buffoon.pdf",
    "15 Peabody's Pets.pdf",
    "16 Your Igloo Or Mine.pdf",
    "17 Loyalty.pdf",
    "18 Finale.pdf",
]

const documents = (props: any) => {
    let leadSheetList: any[] = [];
    leadSheets.forEach((leadSheet: string) => {
        leadSheetList.push(
            <a href={`/pdfs/${leadSheet}`} target='_blank'>{leadSheet}</a>
        );
    });
    return <div className={classes.Documents}>
        <div className={classes.DocumentsTitle}>The Brementown Musicians</div>
        <p>Facebook</p>
        <div className={classes.Social}>
            <a href="https://www.facebook.com/thebrementownmusicians" target="_blank">facebook.com/thebrementownmusicians</a>
        </div>
        <p>Medium Article</p>
        <div className={classes.DocumentsArticle}>
            <a href='https://medium.com/@andrew.rapo/the-brementown-musicians-a-new-musical-334c09b0ec3d?source=friends_link&sk=923bff7ea9932e82c1f22f08988395ce' target='_blank'><img src={mediumArticleImage} alt="The Brementown Musicians" /></a>
            <a href='https://medium.com/@andrew.rapo/the-brementown-musicians-a-new-musical-334c09b0ec3d?source=friends_link&sk=923bff7ea9932e82c1f22f08988395ce' target='_blank'>The Brementown Musicians: A “New” Musical</a>
        </div>
        <p>Lead Sheets</p>
        <div className={classes.DocumentsLeadSheets}>
            <a href='/pdfs/TheBrementownMusiciansSongs.pdf' target='_blank'><img src={leadSheetsImage} alt="The Brementown Musicians" /></a>
            <a href='/pdfs/TheBrementownMusiciansSongs.pdf' target='_blank'>Songbook</a>
            <div className={classes.DocumentsLeadSheetList}>
                {leadSheetList}
            </div>
        </div>
        <br />
    </div>
}

export default documents;
