import React from 'react';

import productImage from '../../assets/images/bremen-loyalty-trans.jpg';
import classes from './Product.module.css';

const product = (props: any) => (
    <div className={classes.Product} style={{height: props.height}}>
        <div className={classes.ProductPanel}>
            <div className={classes.ProductTitle}>The Brementown Musicians</div>
            <p className={classes.ProductSubtitle}>A "new" musical...</p>
            <img src={productImage} alt="The Brementown Musicians" />
            <br />
            <p className={classes.Credits}>
                Book: Fred Sullivan, Jr. & John Sullivan<br />
                Music: S. Andrew Rapo<br />
                Lyrics: Fred Sullivan, Jr.<br />
            </p>
        </div>
    </div>
);

export default product;
