import React from 'react';
// import Aux from '../../hoc/Aux/Aux';
import classes from './Video.module.css';

const video = (props: any) => (
    <div className={classes.Video}>
        <div className={classes.VideoTitle}>The Brementown Musicians</div>
        <p>The Whole Show</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>You Gotta Laugh</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=380" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Doggone Blues</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=717" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>If You Don't Understand It It's Art</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=1100" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Life of a Thief</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=1320" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Lazarus T Wishbone</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=1590" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>The Day Will Come</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=1825" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Brementown</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=2127" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Brementown Reprise</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=2416" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Life In The Theatre</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=2613" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Funny Doesn't Change</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=3638" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Opening Night</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=3954" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Major Buffoon</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=4196" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Peabodys Pets</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=4292" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Your Igloo Or Mine</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=4418" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Loyalty</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=4583" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Finale</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=5087" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>Curtain Call</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xxxX9CsDYW4?start=5238" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
);

export default video;
